<template>
  <van-tabbar
    v-model="active"
    active-color="#FF583E"
    inactive-color="#000002"
    :fixed="true"
    :route="true"
  >
    <van-tabbar-item
      v-for="item in list"
      :key="item.key"
      :badge="item.badge"
      icon="home-o"
      :to="item.path"
    >
      <span>{{ item.name }}</span>
      <template #icon="props">
        <!-- <img :src="props.active ? icon.active : icon.inactive" /> -->
        <svg-icon
          class="tabbar-icon"
          :icon-class="props.active ? item.iconActive : item.icon"
        />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import "../assets/images/svg/home.svg";
import "../assets/images/svg/home_active.svg";
import "../assets/images/svg/goods_active.svg";
import "../assets/images/svg/goods.svg";
import "../assets/images/svg/ticket_active.svg";
import "../assets/images/svg/ticket.svg";
import "../assets/images/svg/account_active.svg";
import "../assets/images/svg/account.svg";
import { Tabbar, TabbarItem } from "vant";
import { mapState } from "vuex";
export default {
  name: "Footer",
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  computed: {
    ...mapState(["cardCount"]),
  },
  data() {
    return {
      active: "/mall/home",
      list: [
        {
          path: "/mall/home",
          name: "首页",
          badge: "",
          icon: "home",
          iconActive: "home_active",
        },
        {
          path: "/mall/goods",
          name: "全部商品",
          badge: "",
          icon: "goods",
          iconActive: "goods_active",
        },
        {
          path: "/mall/ticket",
          name: "我的卡券",
          badge: "",
          icon: "ticket",
          iconActive: "ticket_active",
        },
        {
          path: "/mall/account",
          name: "我的",
          badge: "",
          icon: "account",
          iconActive: "account_active",
        },
      ],
    };
  },
  mounted() {
    this.list[2].badge = this.cardCount || "";
  },
  watch: {
    cardCount: function(val) {
      this.list[2].badge = val || "";
    },
  },
};
</script>

<style lang="less" scoped>
.tabbar-icon {
  width: 0.48rem;
  height: 0.48rem;
}
/deep/ .van-tabbar-item__icon {
  margin-bottom: 0;
}
</style>
