<template>
  <van-overlay :show="show">
    <div class="wrapper" @click.stop>
      <div class="block-box">
        <van-icon class="close-box" name="close" @click="closeModal" />
        <div class="content">
          <div class="bg-img">
            <img src="../assets/images/ticket_modal.png" alt="" />
          </div>
          <h2 class="get-title">卡券领取成功</h2>
          <div class="get-tip">可前往“我的卡券”中查看</div>
          <div class="get-action">
            <van-button class="get-btn" type="primary">立即兑换</van-button>
          </div>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { Overlay, Icon, Button } from "vant";
export default {
  name: "TicketGetModal",
  components: {
    [Overlay.name]: Overlay,
    [Icon.name]: Icon,
    [Button.name]: Button,
  },
  computed: {
    ...mapState(["showTicketModal"]),
    show: {
      get() {
        return this.showTicketModal;
      },
      set() {},
    },
  },
  methods: {
    ...mapMutations(["setTicketShow"]),
    // 关闭弹框
    closeModal() {
      this.setTicketShow({
        show: false,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block-box {
  width: 4.6rem;
  height: 5.34rem;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  .close-box {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 0.4rem;
    color: rgba(255, 255, 255, 0.5);
  }

  .content {
    width: 4.6rem;
    height: 4.24rem;
    background: #fefffe;
    border-radius: 0.16rem;
    position: relative;

    .bg-img {
      position: absolute;
      top: -1.2rem;
      left: 50%;
      transform: translateX(-50%);
      width: 2.4rem;
      height: 2.4rem;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .get-title {
      font-size: 0.36rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      text-align: center;
      margin-top: 0.24rem;
      margin-top: 1.34rem;
    }

    .get-tip {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ff4800;
      margin-top: 0.24rem;
      text-align: center;
    }

    .get-action {
      width: 100%;
      height: 0.64rem;
      display: flex;
      justify-content: center;

      .get-btn {
        width: 3.8rem;
        height: 0.64rem;
        background: #ff4800;
        border-radius: 0.16rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.28rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #fefffe;
        border-color: #ff4800;
        margin-top: 0.8rem;
      }
    }
  }
}
</style>