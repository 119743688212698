<template>
  <van-loading type="spinner">{{ loadingText }}</van-loading>
</template>

<script>
import { Loading } from "vant";
export default {
  name: "Loading",
  props: {
    loadingText: {
      default: "加载中...",
    },
  },
  components: {
    [Loading.name]: Loading,
  },
};
</script>