<template>
  <Flayout>
    <router-view v-if="!webDisabled"></router-view>
    <login-box
      v-model="show"
      @handleCancel="handleCancelLogin"
      @onOk="loginOk"
    />
  </Flayout>
</template>

<script>
import LoginBox from "@/components/LoginBox.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import Flayout from "../components/Flayout.vue";
import mathManage from "@/utils/mathManage";
export default {
  name: "Mall",
  components: {
    Flayout,
    LoginBox,
  },
  computed: {
    ...mapState(["isLogin", "showLogin", "isInWx", "webDisabled"]),
    show: {
      get() {
        return this.showLogin;
      },
      set() {},
    },
  },
  created() {
    if (this.webDisabled) {
      return;
    }
    const cancelWechat = mathManage.getParam("cancelWechat");
    const state = mathManage.getParam("state");
    const loginCode = mathManage.getParam("loginCode");
    if (this.isInWx && !this.isLogin && !cancelWechat) {
      if (this.$route.meta.notWxLogin) return;
      if (state) return;
      if (loginCode) return;
      this.$wxLogin();
    }
  },
  mounted() {
    if (this.webDisabled) {
      return;
    }
    if (this.isLogin) {
      this.dispatchGetCardCount();
      this.getUserInfo();
    }
  },
  methods: {
    ...mapActions(["dispatchGetCardCount", "getUserInfo"]),
    ...mapMutations(["setLoginBox"]),
    handleCancelLogin() {
      this.setLoginBox(false);
    },
    loginOk() {
      window.location.reload();
      // this.setLoginBox(false);
    },
  },
};
</script>