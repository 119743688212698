<template>
  <div class="flayout">
    <div
      v-if="isLogin"
      :class="isSafari ? 'fl-container safariHeight' : 'fl-container'"
    >
      <div class="fl-main">
        <slot></slot>
      </div>
      <div v-if="showTabbar" class="fl-footer">
        <Footer />
      </div>
    </div>
    <div class="loading" v-else><Loading /></div>
    <ticket-get-modal />
  </div>
</template>

<script>
import Loading from "./Loading.vue";
import Footer from "./Footer.vue";
import TicketGetModal from "./TicketGetModal.vue";
import { mapState } from "vuex";
export default {
  name: "Flayout",
  components: {
    Loading,
    Footer,
    TicketGetModal,
  },
  data() {
    // 判断是否在Safari浏览器中
    var ua = navigator.userAgent.toLowerCase();
    let isSafari = false;
    if (
      ua.indexOf("applewebkit") > -1 &&
      ua.indexOf("mobile") > -1 &&
      ua.indexOf("safari") > -1 &&
      ua.indexOf("linux") === -1 &&
      ua.indexOf("android") === -1 &&
      ua.indexOf("chrome") === -1 &&
      ua.indexOf("ios") === -1 &&
      ua.indexOf("browser") === -1
    ) {
      isSafari = true;
    } else {
      isSafari = false;
    }

    return {
      isLogin: true,
      isSafari,
    };
  },
  computed: {
    showTabbar() {
      const { showTabbar } = this.$route.meta || {};
      return Boolean(showTabbar);
    },
  },
};
</script>

<style lang="less" scoped>
.flayout {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .fl-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .fl-main {
      flex: 1;
      overflow: hidden;
    }

    .fl-footer {
      height: 0.96rem;
      position: relative;
      z-index: 20;
    }
  }

  .safariHeight {
    height: calc(100vh - 2rem);
  }

  .loading {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>